import Swal from "sweetalert2";
import moment from "moment";
//import { excelParser } from "./excel-parser";
import HelperMixin from '../../Shared/HelperMixin.vue'
export default {
    name: "AddUser",
    mixins: [HelperMixin],
    async created() {

        await this.CheckLoginStatus();
        await this.GetCities();

        this.GetInfo();


        

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        this.dateNow = mm + "/" + dd + "/" + yyyy;


    },
    
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format("MMMM Do YYYY");
        },

        momentTime: function (date) {
            if (date === null) {
                return "لا يوجد";
            }
            return moment(date).format("DD/MM/YYYY");
        },

    },


    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,


            Info: [],


            CityId: '',
            FacilityId: '',
            ById: '',
            SearchInput: '',
            byDate:'',


            
            Applications: [],
            


            //Info
            SelectedItem: '',



            EditDilog: false,
            AttachmentDiloag: false,
            dialogImageUrl: '',
            dialogVisible: false,



            ruleForm: {
                Id: '',

                ProgramId: '',
                CityId: '',
                FacilityId: '',
                FirstName: '',
                FatherName: '',
                GrandFatherName: '',
                SirName: '',
                Nid: '',
                Phone: '',
                BirthDate: '',
                Gender: '',
                DoctorName: '',

                Attachments: [],
            },



            rules: {
                ProgramId: this.$helper.Required('البرنامج المستهدف '),
                CityId: this.$helper.Required('المدينة  '),
                FacilityId: this.$helper.Required('المرفق الطبي  '),
                FirstName: this.$helper.DynamicArabicEnterRequired('الاسم '),
                FatherName: this.$helper.DynamicArabicEnterRequired('اسم الاب '),
                GrandFatherName: this.$helper.DynamicArabicEnterRequired('اسم الجد '),
                SirName: this.$helper.DynamicArabicEnterRequired('اللقب '),
                DoctorName: this.$helper.DynamicArabicEnterRequired('اسم الطبيب المعالج '),
                Nid: this.$helper.NID(),
                Phone: [
                    {
                        required: true,
                        message: "الرجاء إدخال رقم الهاتف",
                        trigger: "blur",
                    },
                    {
                        min: 9,
                        max: 13,
                        message: "يجب ان يكون طول رقم الهاتف 9 ارقام علي الاقل",
                        trigger: "blur",
                    },
                    { required: true, pattern: /^[0-9]*$/, message: 'الرجاء إدخال ارقام فقط', trigger: 'blur' }
                ],
                Email: [
                    { required: true, message: 'الرجاء إدخال البريد الإلكتروني', trigger: 'blur' },
                    { required: true, pattern: /\S+@\S+\.\S+/, message: 'الرجاء إدخال البريد الإلكتروني بطريقه صحيحه', trigger: 'blur' }
                ],
                BirthDate: this.$helper.Required('تاريخ الميلاد '),
                Gender: this.$helper.Required('الجنس '),

            },


            ruleFormAttahcment: {
                Id: '',
                ImageName: '',
                ImageType: '',
                fileBase64: '',
            },


        };
    },


    methods: {

        FilterByNid() {
            this.Applications = [];
            let code = "";
            if (document.getElementById('selectInputNid') != null) {

                if (document.getElementById('selectInputNid').value == null || document.getElementById('selectInputNid').value == '')
                    return;

                code = document.getElementById('selectInputNid').value;
            }
            if (code.length <= 3)
                return;

            this.$blockUI.Start();
            this.$http.GetApplicationsById(code)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Applications = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    //console.error(err);
                });
        },

        FilterByPass() {
            this.Applications = [];
            let code = "";
            if (document.getElementById('selectInputPass') != null) {

                if (document.getElementById('selectInputPass').value == null || document.getElementById('selectInputPass').value == '')
                    return;

                code = document.getElementById('selectInputPass').value;
            }
            if (code.length <= 3)
                return;

            this.$blockUI.Start();
            this.$http.GetApplicationsById(code)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Applications = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    //console.error(err);
                });
        },

        GetInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            if (this.byDate)
                this.byDate = this.ChangeDate(this.byDate);

            this.$blockUI.Start();  
            this.$http
                .GetApplications(this.pageNo, this.pageSize, this.CityId, this.FacilityId, this.ById, this.byDate)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        Refresh() {
            this.CityId = '';
            this.FacilityId = '';
            this.ById = '';
            this.byDate = '';
            this.GetInfo();
        },


        async GetFacilitiesInfo() {

            this.FacilityId = '',
                this.GetInfo();
                await this.GetFacilities(this.CityId)
        },
       

        OpenEditDilaog(item) {
            this.SelectedItem = item;

            this.ruleForm.Id = item.id;
            this.ruleForm.ProgramId = item.programId;
            this.ruleForm.CityId = item.cityId;
            this.ruleForm.FacilityId = item.facilityId;
            this.ruleForm.FirstName = item.firstName;
            this.ruleForm.FatherName = item.fatherName;

            this.ruleForm.GrandFatherName = item.grandFatherName;
            this.ruleForm.SirName = item.sirName;
            this.ruleForm.Nid = item.nid;
            this.ruleForm.Phone = item.phone;

            this.ruleForm.BirthDate = item.birthDate;
            this.ruleForm.Gender = item.gender;
            this.ruleForm.DoctorName = item.doctorName;
            this.ruleForm.Attachments = item.attachments;
            this.EditDilog = true;

            this.Edit_GetFacilitiesInfo();

        },

        async Edit_GetFacilitiesInfo() {
            await this.GetFacilities(this.ruleForm.CityId)
        },

        SelectCoverAttachment(file) {

            let str = file.raw.type;
            str = str.substring(0, 5);


            if (str != "image" && str != "appli" && str != "text/" && str != "video") {
                this.$helper.ShowMessage('error', 'خطأ بالعملية', 'الرجاء التأكد من نوع الملف');
            }

            if (str != "image")
                file.url = this.ServerUrl + '/assets/img/small-logos/file.png';


            //var reader1 = new FileReader();
            //reader.readAsDataURL(file.raw);

            var $this = this;
            var reader = new FileReader();
            reader.readAsDataURL(file.raw);
            reader.onload = function () {

                if ($this.ruleForm && $this.ruleForm.Attachments.length !== 0) {
                    if (!$this.ruleForm.Attachments.some(item => item.ImageName === file.raw.name)) {
                        //const obj = {
                        //    ImageName: file.raw.name,
                        //    ImageType: file.raw.type,
                        //    Url: file.url,
                        //    fileBase64: reader.result,
                        //}

                        $this.ruleFormAttahcment.Id = $this.ruleForm.Id;
                        $this.ruleFormAttahcment.ImageName = file.raw.name;
                        $this.ruleFormAttahcment.ImageType = file.raw.type;
                        $this.ruleFormAttahcment.fileBase64 = reader.result;
                        $this.$blockUI.Start();
                        $this.$http.AddDocumentEntryAttahcment($this.ruleFormAttahcment)
                            .then(response => {
                                $this.$blockUI.Stop();
                                $this.ruleFormAttahcment.Id = '';
                                $this.ruleFormAttahcment.ImageName = '';
                                $this.ruleFormAttahcment.ImageType = '';
                                $this.ruleFormAttahcment.fileBase64 = '';
                                $this.GetInfo();
                                $this.$helper.ShowMessage('success', 'عملية ناجحة', response.data.message);
                                $this.ruleForm.Attachments.push(response.data.returnObje);
                            })
                            .catch((err) => {
                                $this.$blockUI.Stop();
                                $this.$helper.ShowMessage('error', 'خطأ بعملية الإضافة', err.response.data);
                            });


                        
                    } else {
                        $this.$helper.ShowMessage('error', 'خطأ بالعملية', 'اسم الملف موجود مسبقا');
                        
                    }
                } else {
                    $this.ruleFormAttahcment.Id = $this.ruleForm.Id;
                    $this.ruleFormAttahcment.ImageName = file.raw.name;
                    $this.ruleFormAttahcment.ImageType = file.raw.type;
                    $this.ruleFormAttahcment.fileBase64 = reader.result;
                    $this.$blockUI.Start();
                    $this.$http.AddDocumentEntryAttahcment($this.ruleFormAttahcment)
                        .then(response => {
                            $this.$blockUI.Stop();
                            $this.ruleFormAttahcment.Id = '';
                            $this.ruleFormAttahcment.ImageName = '';
                            $this.ruleFormAttahcment.ImageType = '';
                            $this.ruleFormAttahcment.fileBase64 = '';
                            $this.GetInfo();
                            $this.$helper.ShowMessage('success', 'عملية ناجحة', response.data.message);
                            $this.ruleForm.Attachments.push(response.data.returnObje);
                        })
                        .catch((err) => {
                            $this.$blockUI.Stop();
                            $this.$helper.ShowMessage('error', 'خطأ بعملية الإضافة', err.response.data);
                        });
                }

            };

        },

        Edit_handleRemove(Id) {
            Swal.fire({
                title: "هـل انت متأكد من عملية حذف المرفق ؟",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteDocumentEntryAttachment(Id)
                        .then((response) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data.message);
                            if (this.ruleForm && this.ruleForm.Attachments.length !== 0) {

                                if (this.ruleForm.Attachments.some(item => item.id === Id)) {
                                    const indexToDelete = this.ruleForm.Attachments.findIndex(item => item.id === Id);

                                    if (indexToDelete !== -1) {
                                        this.ruleForm.Attachments.splice(indexToDelete, 1);
                                    }
                                }
                            }
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            });
        },

        handleRemove(file) {

            if (this.ruleForm && this.ruleForm.Attachments.length !== 0) {

                if (this.ruleForm.Attachments.some(item => item.ImageName === file.name)) {
                    const indexToDelete = this.ruleForm.Attachments.findIndex(item => item.ImageName === file.name);

                    if (indexToDelete !== -1) {
                        this.ruleForm.Attachments.splice(indexToDelete, 1);
                    }
                }
            }

            const imageUrl = file.url;
            const imageElement = document.querySelector(`img[src="${imageUrl}"]`);
            const parentElement = imageElement.parentNode;
            const grandparentElement = parentElement.parentNode;

            if (imageElement) {
                grandparentElement.remove();
            }
        },


        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },

        handleDownload(file) {
            const link = document.createElement('a');
            link.href = file.url;
            link.target = '_blank';
            link.download = '';
            link.click();
        },

        RemoveAllAttachment() {
            const elements = document.querySelectorAll('.el-upload-list--picture-card .el-upload-list__item');
            elements.forEach(element => element.remove());
            this.ruleForm.Attachments = [];
        },


        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.Id = Number(this.ruleForm.Id);
                    if (this.loginDetails.userType != 1) {
                        this.ruleForm.PortId = Number(0);
                    }



                    this.$blockUI.Start();
                    this.$http.EditDocumentEntry(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.RemoveAllAttachment();
                            this.GetInfo();
                            this.EditDilog = false;
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        Delete(Id) {
            Swal.fire({
                title: "هـل انت متأكد من عملية الحذف ؟",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteApplications(Id)
                        .then((response) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.GetInfo();
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            });
        },






        Back() {
            this.state = 0;
            this.SelectedItem = '';
            this.RemoveAllAttachment();
            this.GetInfo(this.pageNo);
        },

        ChangeDate(date) {
                if (date === null) {
                    return "فارغ";
                }
                return moment(date).format("YYYY-MM-DD");
        },





    },
};
