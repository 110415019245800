import axios from "axios";

axios.defaults.headers.common["X-CSRF-TOKEN"] = document.cookie.split("=")[1];

//const baseUrl = 'http://localhost:4810/Api';

export default {
    // ********************************************************************| Authintecations |***********************************************************

    login(bodyObjeect) {
        return axios.post(`/Security/loginUser`, bodyObjeect);
    },

    AddMuntisbl(bodyObjeect) {
        return axios.post(`/api/admin/Municipalities/Add`, bodyObjeect);
    },

    IsLoggedin() {
        return axios.get(`/Security/IsLoggedin`);
    },

    Logout() {
        return axios.post(`/Security/Logout`);
    },







    // ********************************************************************| Dictionaries |***********************************************************



    // Cities
    GetCitie(pageNo, pageSize) {
        return axios.get(`api/admin/Dictionaries/Cities/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    GetCities() {
        return axios.get(`api/admin/Dictionaries/Cities/GetAll`);
    },

    AddCities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Cities/Add`, bodyObject);
    },

    EditCities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Cities/Edit`, bodyObject);
    },

    DeleteCities(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Cities/Delete`);
    },


    // Facilities
    GetFacilities(pageNo, pageSize, CityId) {
        return axios.get(`api/admin/Dictionaries/Facilities/Get?pageno=${pageNo}&pagesize=${pageSize}&CityId=${CityId}`);
    },

    GetAllFacilities(CityId) {
        return axios.get(`api/admin/Dictionaries/Facilities/GetById?CityId=${CityId}`);
    },

    AddFacilities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Facilities/Add`, bodyObject);
    },

    EditFacilities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Facilities/Edit`, bodyObject);
    },

    DeleteFacilities(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Facilities/Delete`);
    },











    // ********************************************************************| DocumentEntry  |***********************************************************


    AddDocumentEntry(bodyObject) {
        return axios.post(`api/admin/Applications/Add`, bodyObject);
    },

    EditDocumentEntry(bodyObject) {
        return axios.post(`api/admin/Applications/Edit`, bodyObject);
    },

    GetApplicationsById(code) {
        return axios.get(`api/admin/Applications/GetById?code=${code}`);
    },

    GetApplications(pageNo, pageSize, CityId, FacilityId, ById, byDate) {
        return axios.get(`api/admin/Applications/Get?pageNo=${pageNo}&pageSize=${pageSize}&CityId=${CityId}&FacilityId=${FacilityId}
        &ById=${ById}&byDate=${byDate}`);
    },

    DeleteApplications(Id) {
        return axios.post(`api/admin/Applications/${Id}/Delete`);
    },



    // Attachemtn
    AddDocumentEntryAttahcment(bodyObject) {
        return axios.post(`api/admin/Applications/Attachments/Add`, bodyObject);
    },

    GetDocumentEntryAttachment(Id) {
        return axios.get(`api/admin/Applications/Attachments/Get?Id=${Id}`);
    },

    DeleteDocumentEntryAttachment(Id) {
        return axios.post(`api/admin/Applications/${Id}/Attachments/Delete`);
    },




    //********************************************************************| Contact Us Info |***********************************************************

    GetContactUs(pageNo, pageSize) {
        return axios.get(`api/admin/Dictionaries/ContactUs/Get?pageNo=${pageNo}&pagesize=${pageSize}`);
    },









    // ********************************************************************| Users |***********************************************************
    GetUsers(pageNo, pageSize, UserType, PortId) {
        return axios.get(`api/admin/User/Get?pageNo=${pageNo}&pagesize=${pageSize}&userType=${UserType}&PortId=${PortId}`);
    },

    AddUser(bodyObject) {
        return axios.post("api/admin/User/Add", bodyObject);
    },

    EditUser(bodyObject) {
        return axios.post("api/admin/User/Edit", bodyObject);
    },

    ChangeStatusUser(Id) {
        return axios.post(`api/admin/User/${Id}/ChangeStatus`);
    },

    RestePassword(Id) {
        return axios.post(`api/admin/User/${Id}/RestePassword`);
    },

    DeleteUser(Id) {
        return axios.post(`api/admin/User/${Id}/Delete`);
    },

    UploadImage(bodyObject) {
        return axios.post("/Api/Admin/User/UploadImage", bodyObject);
    },

    EditUsersProfile(bodyObject) {
        return axios.post("/Api/Admin/User/EditUsersProfile", bodyObject);
    },

    ChangePassword(userPassword) {
        return axios.post(`/Api/Admin/User/ChangePassword`, userPassword);
    },

















    GetDashboardInfo() {
        return axios.get(`api/admin/Prisoners/GetDashboardInfo`);
    },






















};
